<template>
  <div class="container pb-5">
    <div class="row">
      <div class="col-8 offset-2">
        <h5 class="font-weight-light">
          <b> Vamos começar a simulação do seguro </b>
        </h5>
        <hr class="mt-3 mb-3" />
        <div class="alert alert-info">
          <p>
            Ótimo! Agora vamos saber o valor da assinatura do seguro da bike
            desejada. Por favor informe os dados abaixo.
          </p>
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <form id="etapaSimulacaoInicial">
            <div class="row">
              <div class="col-6">
                <b-form-group label="Marca">
                  <ValidationProvider
                    name="marca"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <v-select
                      v-model="marcaBike"
                      :options="lstMarcas"
                      label="nome"
                      :disabled="loadingMarca"
                      :class="classes"
                    >
                      <div slot="no-options">Nenhum resultado.</div>
                    </v-select>
                    <div v-if="loadingMarca">Carregando...</div>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Modelo</label>
                  <ValidationProvider
                    name="modelo"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Descreva o modelo"
                      v-model="modeloBike"
                      :class="classes"
                      maxlength="100"
                      autocomplete="nope"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <b-form-group label="Informe o valor (sem os centavos)">
                  <ValidationProvider
                    name="valor"
                    :rules="'required|valor_bike'"
                    v-slot="{ errors, classes }"
                  >
                    <money
                     
                      v-model="valorBike"
                      v-bind="{
                        decimal: ',',
                        thousands: '.',
                        prefix: 'R$ ',
                        suffix: '',
                        precision: 0,
                      }"
                      :class="['form-control', classes]"
                    ></money>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </div>
               <div class="col-6">
                    <div class="form-group">
                      <label>Nascimento</label>
                      <ValidationProvider ref="nascimento" name="Data de Nascimento"
                        rules="required|data_nascimento|data_maior_idade" v-slot="{ errors, classes }">
                        <input type="tel" class="form-control" :class="classes" v-model="nascimento" v-mask="'##/##/####'"
                          autocomplete="nope" placeholder="Nascimento" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                      <div class="col-6">
                  <div class="form-group">
                    <label>Estado</label>
                    <ValidationProvider name="estado" rules="required" v-slot="{ errors, classes }">
                      <select class="form-control" v-model="estado" :class="classes">
                        <option :value="undefined">Selecione...</option>
                        <option>AC</option>
                        <option>AL</option>
                        <option>AM</option>
                        <option>AP</option>
                        <option>BA</option>
                        <option>CE</option>
                        <option>DF</option>
                        <option>ES</option>
                        <option>GO</option>
                        <option>MA</option>
                        <option>MG</option>
                        <option>MS</option>
                        <option>MT</option>
                        <option>PA</option>
                        <option>PB</option>
                        <option>PE</option>
                        <option>PI</option>
                        <option>PR</option>
                        <option>RJ</option>
                        <option>RN</option>
                        <option>RO</option>
                        <option>RR</option>
                        <option>RS</option>
                        <option>SC</option>
                        <option>SE</option>
                        <option>SP</option>
                        <option>TO</option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

              <div class=" col-12 my-2 ">
                <b-alert show>
                  Você sabia que nós temos seguro para o seu acessório?
                </b-alert>
              </div>
              <div class="col-12 mb-3">
                  <b-form-checkbox v-model="coberturaCheck" variant="success">
                    Quero adicionar cobertura para acessório
                  </b-form-checkbox>
              </div>
            
                <div class="form-group col-6" v-if="coberturaAcessorio">
                  <label
                    >Descrição do acessório <small>(obrigatório)</small>
                  </label>
                  <ValidationProvider
                    name="descrição do acessório"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <select
                      class="form-control"
                      v-model="nomeAcessorio"
                      :class="classes"
                      data-test-id="input-estado"
                    >
                      <option :value="undefined"
                        >Selecione o acessório...</option
                      >
                      <option value="Ciclocomputador/ GPS/ Garmin"
                        >Ciclocomputador/ GPS/ Garmin</option
                      >
                      <!-- <option value="Medidor de potência"
                                >Medidor de potência</option
                              >
                              <option value="Sensor de cadência"
                                >Sensor de cadência</option
                              >
                              <option value="Capacete">Capacete</option> -->
                    </select>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group col-6" v-if="coberturaAcessorio">
                  <label
                    >Marca do acessório <small>(obrigatório)</small>
                  </label>
                  <ValidationProvider
                    name="marca do acessório"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="marcaAcessorio"
                      :class="classes"
                      maxlength="100"
                      autocomplete="nope"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group col-6" v-if="coberturaAcessorio">
                  <label
                    >Valor do acessório <small>(obrigatório)</small>
                  </label>
                  <ValidationProvider
                    name="valor do acessório"
                    :rules="'required|valor_acessorio'"
                    v-slot="{ errors, classes }"
                  >
                    <money
                      v-model="valorAcessorio"
                      v-bind="{
                        decimal: ',',
                        thousands: '.',
                        prefix: 'R$ ',
                        suffix: '',
                        precision: 0,
                      }"
                      :class="['form-control', classes]"
                    ></money>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6 text-left">
                <!-- <button
                    class="btn btn-link"
                    @click.prevent="back('etapaDadosCliente')"
                  >
                    <i class="fas fa-chevron-left"></i> Voltar
                  </button> -->
              </div>
              <div class="col-6 text-right">
                <!-- <button
                    class="btn btn-link"
                    :disabled="invalid"
                    @click.prevent="etapaCompInter()"
                  >
                    Continuar <i class="fas fa-chevron-right"></i>
                  </button> -->

                <button
                  class="btn btn-link"
                  :disabled="invalid"
                  @click.prevent="rota()"
                >
                  Continuar <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
      <ModalInfor
      :showModalInfor="showModalInfor"
      :titulo="titulo"
      :mensagemHtml="mensagemHtml"
      @hide="hideModalInfor"
    />
  </div>
</template>

<script>
import auth from "../../auth";
import axios from "axios";
import Swal from "sweetalert2";
import captureError from "../../helpers/captureError";
import config from "../../config";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Money } from "v-money";

export default {
  components: { ModalInfor: () => import("../../components/Modal/ModalInfor.vue"),
    vSelect,
    Money,
  },
  data() {
    return {
      auth,
      config,
      cliente: {},
      modeloBike: undefined,
      marcaBike: undefined,
      loadingMarca: false,
      valorBike: 0,
      lstMarcas: [],
      idKakau: 0,
      estado:undefined,
      nascimento:undefined,

      loading: false,
      //Cobertura acessório
      coberturaAcessorio: false,
      marcaAcessorio: undefined,
      nomeAcessorio: undefined,
      valorAcessorio: 0,
      valorPlanoAcessorio: undefined,

      //modal infor

      titulo: "",
      showModalInfor: false,
      mensagemHtml: "",
      coberturaCheck: false,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (!userInfo.cnpj) return this.$router.replace("/parceiro");
    this.buscarMarcas();
  },
  watch: {
    modeloBike: function(value) {
      this.modeloBike = value.toUpperCase();
    },
    coberturaCheck: function(newValue) {
      if (newValue && this.step != 3) {
        this.abrirModalInfor();
      } else if (this.step != 3) {
        this.hideModalInfor({
          modificou: false,
        });
      }
    },
   
  },
  methods: {
    abrirModalInfor() {
      this.titulo = "Informação importante";
      this.mensagemHtml = `<strong>Atenção:</strong>  Você está incluindo cobertura para um acessório. Em caso de
            sinistro, será obrigatório a apresentação da nota fiscal do aparelho
            com a data anterior a contratação do seguro. Você possui a nota
            fiscal?`;
      this.showModalInfor = true;
    },
    hideModalInfor(value) {
      this.showModalInfor = false;
      if (!value?.modificou) {
        this.marcaAcessorio = undefined;
        this.nomeAcessorio = undefined;
        this.valorAcessorio = 0;
        this.valorPlanoAcessorio = undefined;
        this.coberturaAcessorio = false;
        this.coberturaCheck = false;
      } else if (value.modificou) {
        this.coberturaAcessorio = true;
      }
    },
    async buscarMarcas() {
      try {
        this.loadingMarca = true;
        var response = await axios.get(`${config.API_URLV2}/marca/marcas`);

        this.lstMarcas = response.data || [];
        this.loadingMarca = false;
      } catch (error) {
        captureError(error, this.cliente.email, undefined, this.cliente.nome);
        this.loadingMarca = false;
        Swal.fire({
          title: "Não foi possível buscar as marcas",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
 
    async buscarMarcasParaSeguroKakau() {
      var responseMarcas = await axios.get(
        `${config.API_URLV2}/marca/marcasFiltro/${this.cliente.marcaBike}`
        
      );
      if (responseMarcas.data != null) {
        this.marcaBike = responseMarcas.data;
      } else {
        this.buscarMarcas();
      }
    },
    //foi optado deixar assim devido a avisos do v-select
    rota() {
      this.$router.push({
        name: "dados-cliente",
        params: {
          marcaBike: this.marcaBike,
          modeloBike: this.modeloBike,
          valorBike: this.valorBike,
          idKakau: this.idKakau,
          cliente: this.cliente,
          coberturaAcessorio: this.coberturaAcessorio,
          marcaAcessorio: this.marcaAcessorio,
          nomeAcessorio: this.nomeAcessorio,
          valorAcessorio: this.valorAcessorio,
          nascimento:this.nascimento,
          ufCliente:this.estado
        },
      });
    },
  },
};
</script>
